/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/me.jpg"
        width={50}
        height={50}
        quality={95}
        alt="Coleman Rollins"
      />
      <div>
        <p>
          I'm <strong>Coleman</strong>, a full-stack web engineer interested in
          internet things, eating food, and generating passive income. I develop
          and lead web projects for{" "}
          <a href="https://grafana.com/" target="_blank">
            Grafana Labs
          </a>
          , and have previously worked for{" "}
          <a href="https://drw.com" target="_blank">
            DRW Trading
          </a>{" "}
          as well as{" "}
          <a href="http://50000feet.com" target="_blank">
            50000feet
          </a>
          .
        </p>
        <p>
          Check out some of my posts. I'll be writing about navigating the tech
          job market, practicing mindfulness, some amateur algorithmic trading,
          and maybe some cooking.
        </p>
      </div>
    </div>
  )
}

export default Bio
